import {makeStyles} from '@material-ui/core/styles';
import {EditAutoAttendantForm} from '../../views/AutoAttendants/Details/AutoAttendantsDetails';
import {EditExtensionForm} from '../../views/Extensions/ExtensionDetails/utils';

export const useTabStyles = makeStyles(() => ({
    loader: {
        height: 500,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export interface TabInterface {
    handleSubmitForm?: (
        formData: Partial<EditExtensionForm | EditAutoAttendantForm>,
    ) => void;
    handleInitDataChange?: (
        formData: Partial<EditExtensionForm | EditAutoAttendantForm>,
    ) => void;
    handleDirtyChange?: (funcName: string, isDirty: boolean) => void;
    handleSetSubmitFunc?: (funcName: string, func: () => void) => void;
    handleSetIsValidFunc?: (funcName: string, func: () => boolean) => void;
    showRestricted?:boolean,
    isActive:boolean,
    tabName?:string,
    tabIndex?:number,
    callback?: () => void

}