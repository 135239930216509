import {callSettingsDefaultValues} from '../components/Forms/AutoAttendants/CallSettings/CallSettingsForm.utils';
import {useIncomingAAFormTab} from './useIncomingAAFormTab';
import {useMemo} from 'react';
import {getServiceFeatureLockedStatus, getServiceFeatureValue,} from '../utils/extensions/getServiceValue';
import {ServiceFeatureName} from '../store/types/ServiceFeature';
import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {ExtensionType} from "../store/types/Extension";

export const useAutoAttendantCallSettingsFormData = () => {
    const incomingInitData = useIncomingAAFormTab();

    const {
        serviceFeatures, callBarringRules, mohDetails,
    } = useSelector(
        (state: ReduxState) => state.extensions,
    );

    const {incomingTab} = useSelector(
        (state: ReduxState) => state.autoAttendants
    );

    const accountInfo = useSelector<ReduxState, ExtensionType | undefined>(state => state.autoAttendants?.autoAttendantInfo?.account_info);

    const initialValues = useMemo(() => {
        return {
            ...callSettingsDefaultValues,
            ...incomingInitData.initValues,
            //Call barrings
            callBarringStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallBarring,
                    serviceFeatures,
                ) === 'Y',
            callBarringLocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.CallBarring,
                serviceFeatures,
            ),
            callBarringItems: callBarringRules || [],
            individualRules: false,

            //Call recordings
            callRecordingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                ) === 'Y',
            callRecordingBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.CallRecording,
                serviceFeatures,
            ),
            outgoingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'call_recording_out',
                ) === '1',
            incomingStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'call_recording_in',
                ) === '2',
            redirectedStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'call_recording_redirect',
                ) === '4',
            playCallRecordingAnnouncementToAllStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'audible_notification',
                ) === 'Y',
            sendTheCallRecordingViaEmailStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'mail_recording',
                ) === 'Y',
            allowToStartStopRecordingManually:
                getServiceFeatureValue(
                    ServiceFeatureName.CallRecording,
                    serviceFeatures,
                    'on_demand',
                ) === 'Y',
            DISAPassword: incomingTab?.DISAPassword || '',
            DISASwitcher: incomingTab?.enableDisa === 'Y',
            onHoldMusicStatus: mohDetails?.isEnabled || false,
            onHoldMusicName:
                mohDetails?.items?.find(
                    (v) => v.i_moh === mohDetails?.selectedValueId,
                )?.name ?? '',

                
            //Prefered IVR Language
            preferedIvrLanguageStatus:
                getServiceFeatureValue(
                    ServiceFeatureName.PreferedIvrLanguage,
                    serviceFeatures,
                ) === 'Y',
            preferedIvrLanguageBlocked: getServiceFeatureLockedStatus(
                ServiceFeatureName.PreferedIvrLanguage,
                serviceFeatures,
            ),
            preferedIvrLanguageCode:
                getServiceFeatureValue(
                    ServiceFeatureName.PreferedIvrLanguage,
                    serviceFeatures,
                    'iso_639_1',
                ),
        };
    }, [incomingInitData, serviceFeatures, callBarringRules, incomingTab,mohDetails]);

    return {
        initialValues,
        data: {
            email: accountInfo?.email,
            mohItems:
                mohDetails?.items.map((v) => ({
                    status: '',
                    id: v.i_moh || 0,
                    name: v.name,
                })) || [],
        }
    }
};
