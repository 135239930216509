import React, {useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import CustomKeyboardDateTimePicker from '../../../KeyboardDateTimePicker/KeyboardDateTimePicker';
import TextField from '../../../TextField/TextField';
import Button from '../../../Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../../store';
import dayjs from '../../../../services/customDayJs';
import {ReduxState} from '../../../../store/types';
import {CallHistory} from '../../../../store/types/CallHistory';
import {convertUserLocalTimeToUtc} from '../../../../utils/dateWithTimezoneConversion';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import {DialogButton} from '../../../AlertDialog/DialogContainer';
import DataGrid from '../../../DataGrid/DataGrid';
import {PaginationMode} from '../../../DataGrid/types';
import {
    CallHistoryFormProps,
    CallRecordingProps,
    dateFormat,
    generateColumns,
    useStyles,
} from './CallHistoryForm.utils';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import Checkbox from '../../../Checkbox/Checkbox';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import CallRecordInformationDialog from '../../../Calls/CallRecordInformationDialog/CallRecordInformationDialog';
import {prepareCallRecordName} from '../../../../utils/calls/prepareCallRecordName';
import {dateFromDateTillValidationSchema} from "../../../Extensions/CallHistoryDialog.utils";

const CallHistoryForm: React.VFC<CallRecordingProps> = ({
    accountId,
    deletePermission,
    downloadPermission,
    recordPermission,
                                                            downloadTranscriptionPermission
}) => {
    const [isDownloadingGoingOn, setIsDownloadingGoingOn] = useState<boolean[]>(
        [],
    );
    const classes = useStyles();
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [isCallDialogOpen, setIsCallDialogOpen] = useState(false);
    const [callRecordInformation, setCallRecordInformation] = useState<
        CallHistory | undefined
    >(undefined);

    const { items, total, isLoading } = useSelector<
        ReduxState,
        { items: CallHistory[]; total: number; isLoading: boolean }
    >((state) => state.calls.extensionCallHistory);

    const toggleTooltipVisibility = (index: number) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = false;
        setIsDownloadingGoingOn(newDownloading);
    };

    const customerCurrency = useSelector<ReduxState, string>(
        (state) => state.calls.customerCurrency || '',
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const initialValues = useMemo(
        () => ({
            from: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .subtract(1, 'month')
                .format(dateFormat),
            till: dayjs
                .utc()
                .utcOffset(timezoneOffset / 60)
                .format(dateFormat),
            cli: '',
            cld: '',
            unsuccessfulCalls: false,
        }),
        [timezoneOffset],
    );

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        setFieldError,
    } = useFormik<CallHistoryFormProps>({
        initialValues,
        onSubmit: (form) => {
            getCallHistory(form, false);
        },
        validationSchema: dateFromDateTillValidationSchema(timezoneOffset, userDateTimeFormat),
        enableReinitialize: true,
        validateOnChange: false,
    });

    const getCallHistory = (
        formData: CallHistoryFormProps,
        withInitCalls?: boolean,
        limit?: number,
        offset?: number,
    ) => {
        if (accountId) {
            dispatch(
                actions.getExtensionCallHistory.request({
                    i_account: accountId,
                    limit: limit || 10,
                    offset: offset || 0,
                    ...formData,
                    cli: formData.cli ? `${formData.cli}%` : undefined,
                    cld: formData.cld ? `${formData.cld}%` : undefined,
                    from: convertUserLocalTimeToUtc(
                        formData.from,
                        timezoneOffset,
                    ),
                    till: convertUserLocalTimeToUtc(
                        formData.till,
                        timezoneOffset,
                    ),
                    withInitCalls,
                    unsuccessfulCalls: formData.unsuccessfulCalls,
                }),
            );
        }
    };

    useEffect(() => {
        getCallHistory(values, true);
    }, [accountId, values]);

    const downloadFile = (
        i_xdr: number,
        call_recording_id: string,
        fileName: string,
        index: number,
    ) => {
        const newDownloading = [...isDownloadingGoingOn];
        newDownloading[index] = true;
        setIsDownloadingGoingOn(newDownloading);
        toast(t<string>('tooltips:ringGroups.downloadWillStartShortly'));
        dispatch(
            actions.getExtensionCallHistoryFile.request({
                i_xdr,
                call_recording_id,
                fileName,
                callback: () => toggleTooltipVisibility(index),
            }),
        );
    };

    const removeItems = (items: CallHistory[]) => {
        if (accountId) {
            dispatch(
                actions.removeCallRecordings.request({
                    items,
                    getDataExtensionRequestPayload: {
                        i_account: accountId,
                        limit: 10,
                        offset: 0,
                        ...values,
                        cli: values.cli ? `${values.cli}%` : undefined,
                        cld: values.cld ? `${values.cld}%` : undefined,
                        from: convertUserLocalTimeToUtc(
                            values.from,
                            timezoneOffset,
                        ),
                        till: convertUserLocalTimeToUtc(
                            values.till,
                            timezoneOffset,
                        ),
                        unsuccessfulCalls: values.unsuccessfulCalls,
                    },
                }),
            );
            setCallRecordInformation(undefined);
        }
    };

    const deleteCallRecording = () => {
        callRecordInformation && removeItems([callRecordInformation]);
        setIsRemoveModalOpen(false);
    };

    const columns = useMemo(() =>
            generateColumns(
                t,
                classes,
                timezoneOffset,
                customerCurrency,
                downloadFile,
                (someValue) => {
                    setIsCallDialogOpen(true);
                    setCallRecordInformation(someValue);
                },
                userDateTimeFormat,
                isDownloadingGoingOn,
                !!callRecordInformation,
                downloadPermission,
                recordPermission,
            ),
        [
            t,
            classes,
            timezoneOffset,
            customerCurrency,
            downloadFile,
            setIsCallDialogOpen,
            setCallRecordInformation,
            userDateTimeFormat,
            isDownloadingGoingOn,
            callRecordInformation,
            downloadPermission,
            recordPermission,
        ]
    );

    return (
        <div className={classes.mainContainer}>
            <div data-testid="call-history-form">
                <Grid item className={classes.filtersContainer}>
                    <Grid item className={classes.itemsContainer}>
                        <Grid item className={classes.inputsContainer}>
                            <CustomKeyboardDateTimePicker
                                id="from"
                                label={t('common:fromDate')}
                                value={values.from}
                                onChange={(v) => setFieldValue('from', v)}
                                dataQa={'call-history-filter-from'}
                                dataTestId={'call-history-filter-from'}
                                handleSubmit={handleSubmit}
                                userDateTimeFormat={userDateTimeFormat}
                                helperText={errors.from}
                                setFieldError={setFieldError}
                                skipPermission
                            />
                            <CustomKeyboardDateTimePicker
                                id="till"
                                label={t('common:toDate')}
                                value={values.till}
                                onChange={(v) => setFieldValue('till', v)}
                                dataQa={'call-history-filter-till'}
                                dataTestId={'call-history-filter-till'}
                                handleSubmit={handleSubmit}
                                userDateTimeFormat={userDateTimeFormat}
                                helperText={errors.till}
                                setFieldError={setFieldError}
                                skipPermission
                            />
                            <TextField
                                id="cli"
                                label={t('screens:calls.caller')}
                                onChange={handleChange}
                                value={values.cli}
                                dataQa={'call-history-filter-calling-number'}
                                handleSubmit={handleSubmit}
                                skipPermission
                            />
                            <TextField
                                id="cld"
                                label={t('screens:calls.destination')}
                                onChange={handleChange}
                                value={values.cld}
                                dataQa={'call-history-filter-called-number'}
                                handleSubmit={handleSubmit}
                                skipPermission
                            />
                        </Grid>
                        <Button
                            primary
                            accent
                            dataQa="call-history-search"
                            className={classes.button}
                            onClick={() => handleSubmit()}
                            skipPermission
                        >
                            {t('common:search')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid>
                    <Box
                        display="flex"
                        alignItems="center"
                        className={classNames(classes.checkbox)}
                    >
                        <Checkbox
                            checked={values.unsuccessfulCalls}
                            dataQa="unsuccessful-calls-switch"
                            dataTestId="unsuccessful-calls-switch"
                            onChange={(value) =>
                                setFieldValue(
                                    'unsuccessfulCalls',
                                    value.target.checked,
                                )
                            }
                            label={t('screens:calls.showUnsuccessfulCalls')}
                            skipPermission
                        />
                        <IconWithTooltip
                            dataQa="unsuccessful-tooltip"
                            tooltipText={t(
                                'tooltips:callSettings.showUnsuccessfulAttempts',
                            )}
                        />
                    </Box>
                </Grid>
            </div>

            <DataGrid<CallHistory>
                columns={columns}
                data={items.map((v, index) => ({ ...v, id: index }))}
                rowCount={total}
                loading={isLoading}
                onPageChange={(v) =>
                    getCallHistory(
                        values,
                        false,
                        v.pageSize,
                        v.pageSize * v.page,
                    )
                }
                onPageSizeChange={(v) =>
                    getCallHistory(values, false, v.pageSize, 0)
                }
                paginationMode={PaginationMode.Server}
                centeredRows
                narrowRows
                customRowHeight={63}
                initialPageSize={10}
                classes={{
                    tableContainer: classes.tableContainer,
                }}
            />

            <CallRecordInformationDialog
                isOpen={isCallDialogOpen}
                toggleVisibility={() => {
                        setIsCallDialogOpen(false);
                    }
                }
                callHistory={callRecordInformation}
                customerCurrency={customerCurrency}
                timezoneOffset={timezoneOffset}
                onDeleteClick={() => {
                    setIsRemoveModalOpen(true);
                }}
                onDownloadClick={() =>
                    callRecordInformation?.i_xdr &&
                    downloadFile(
                        callRecordInformation.i_xdr,
                        callRecordInformation.cr_download_ids?.[0] || '',
                        prepareCallRecordName(
                            callRecordInformation,
                            timezoneOffset,
                        ),
                        0,
                    )
                }
                userDateTimeFormat={userDateTimeFormat}
                isDownloadingGoingOn={isDownloadingGoingOn}
                deletePermission={deletePermission}
                downloadPermission={downloadPermission}
                downloadTranscriptionPermission={downloadTranscriptionPermission}
            />

            <AlertDialog
                isOpen={isRemoveModalOpen}
                dataQa="remove-call-history"
                contentClass="medium-width-modal-delete"
                hideHeader={true}
                className={classes.container}
                description={
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {t('screens:calls.deleteCallHistory', {
                            name:
                                callRecordInformation &&
                                prepareCallRecordName(
                                    callRecordInformation,
                                    timezoneOffset,
                                ),
                        })}
                    </span>
                }
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setIsRemoveModalOpen(false)}
                    />,
                    <DialogButton
                        key="delete"
                        label={t('common:delete')}
                        className={classes.primaryModalButton}
                        onClick={deleteCallRecording}
                    />,
                ]}
            />
        </div>
    );
};

export default CallHistoryForm;
