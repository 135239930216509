import React, {useRef} from 'react';
import {Box, Grid} from '@material-ui/core';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {actions} from '../../store';
import {useDispatch} from 'react-redux';
import IconWithTooltip from '../Tooltip/IconWithTooltip';
import DialogContainer, {DialogButton} from '../AlertDialog/DialogContainer';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import {initialValues, MusicUpdateFormProps, UploadFileProps, UploadFileValidationSchema, useStyles,} from './utils';
import {useFormik, validateYupSchema, yupToFormErrors} from 'formik';
import {VoiceFileContentType} from "../../store/types/Voicemail";

const UploadFile: React.FC<UploadFileProps> = (
    {
        isModalOpen,
        setIsModalOpen,
        onSave,
        items,
    }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const classes = useStyles();
    const mohFile = useRef<HTMLInputElement>(null);

    const {
        values,
        setFieldValue,
        handleChange,
        handleSubmit,
        setFieldError,
        errors,
        resetForm,
    } = useFormik<MusicUpdateFormProps>({
        initialValues,
        onSubmit: () => {
            if (values.file) {
                onSave
                    ? onSave(values.file, values.fileName, onClose)
                    : dispatch(
                        actions.uploadMusicFile.request({
                            file: values.file,
                            name: values.fileName,
                            callback: onClose,
                            errorCallback: () =>
                                setFieldError(
                                    'fileName',
                                    t('errors:callSettings.upload'),
                                ),
                        }),
                    );
            }
        },
        validateOnChange: false,
        validate: (value) => {
            try {
                validateYupSchema(value, UploadFileValidationSchema, true, {
                    items: items,
                });
            } catch (err) {
                return yupToFormErrors(err);
            }

            return {};
        },
    });

    const onClose = () => {
        setIsModalOpen(false);
        resetForm();
    };

    const actionButtons = [
        <DialogButton
            key="cancel"
            label={t('common:cancel')}
            onClick={onClose}
        />,
        <DialogButton
            key="save"
            dataQa="extension-save-button"
            onClick={handleSubmit}
            label={t('common:save')}
            primary
            disabled={!(values.file && values.fileName && values.musicFile)}
        />,
    ];

    const prepareOggFile = (file: BlobPart) => {
        const audioBlob = new Blob([file], {type: VoiceFileContentType.AudioOgg});
        // @ts-ignore
        return new File([audioBlob], file.name, {
            type: VoiceFileContentType.AudioOgg,
        })
    }

    return (
        <DialogContainer
            isOpen={isModalOpen}
            header={t('common:uploadNewMusicFile')}
            headerClass={classes.header}
            dialogActionsButtons={actionButtons}
            className={classes.root}
        >
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.columnItemsContainer,
                )}
            >
                <div className={classes.inputs}>
                    <Box className={classes.fileRow}>
                        <Box className={classes.fileBox}>
                            <Grid item>
                                <input
                                    type="file"
                                    id="file"
                                    accept={'audio/*'}
                                    ref={mohFile}
                                    style={{display: 'none'}}
                                    data-testid="moh-file-upload"
                                    onChange={(event) => {

                                        event?.stopPropagation();
                                        event?.preventDefault();

                                        if (event?.target?.files) {

                                            let audioFile;
                                            if (event.target.files[0] && event.target.files[0].type === VoiceFileContentType.VideoOgg) {
                                                audioFile = prepareOggFile(event.target.files[0])
                                            }

                                            setFieldValue(
                                                'musicFile',
                                                audioFile ? audioFile.name : event.target.files[0]?.name,
                                            );

                                            setFieldValue(
                                                'file',
                                                audioFile ?? event.target.files[0],
                                            );
                                            setFieldError('file', undefined);
                                        }
                                    }}
                                />
                                <TextField
                                    id="file"
                                    label={t('common:file')}
                                    dataQa="create-extension-name-input"
                                    warningIcon={false}
                                    className={classes.file}
                                    value={values.musicFile}
                                    required
                                    helperText={errors?.file ? errors.file : ''}
                                    readOnly={true}
                                />

                                <Box>
                                    <span
                                        className={classes.descriptionFormats}
                                    >
                                        {t('common:supportedFileFormats')} mp3,
                                        wav, ogg, au
                                    </span>
                                </Box>
                            </Grid>
                        </Box>
                        <Button
                            dataQa="upload-button"
                            primary
                            accent
                            className={classes.uploadMargin}
                            onClick={() => {
                                mohFile.current?.click();
                            }}
                        >
                            {t('common:browse')}
                        </Button>
                    </Box>
                    <TextField
                        id="fileName"
                        label={t('screens:callSettings.musicTitle')}
                        className={classes.file}
                        onChange={handleChange}
                        setFieldError={setFieldError}
                        required
                        value={values.fileName}
                        iconPosition="end"
                        icon={
                            <IconWithTooltip
                                dataQa="musicTitle-name-tooltip"
                                tooltipText={t(
                                    'tooltips:callSettings.nameUploadedFile',
                                )}
                            />
                        }
                        helperText={errors?.fileName ? errors.fileName : ''}
                        warningIcon={false}
                        maxLength={64}
                    />
                </div>
            </Grid>
        </DialogContainer>
    );
};

export default UploadFile;
