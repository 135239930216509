import {FaxFormat} from '../../components/Forms/AutoAttendants/Incoming/utils';

export enum EmailOption {
    None = 'none',
    Forward = 'forward',
    ForwardAsAttachment = 'fwd_as_attachment',
    Copy = 'copy',
    Notify = 'notify',
}

export enum VoicemailGreeting {
    Standard = 'standard',
    Personal = 'personal',
    Name = 'name',
    ExtendedAbsence = 'extended',
}

export enum VoicemailFileFormat {
    AU = 'au',
    WAV = 'wav',
    MP3 = 'mp3',
}

export enum VoiceFileContentType {
    VideoOgg = 'video/ogg',
    AudioOgg = 'audio/ogg'
}

export interface VoicemailSettings {
    ext_email: string;
    fax_file: FaxFormat;
    greetings: VoicemailGreeting;
    announce_dt: 'no' | 'yes';
    password: string;
    prompt_levels: string;
    password_ask: 'no' | 'yes';
    ext_email_vm_fmt: VoicemailFileFormat;
    ext_email_action: EmailOption;
    auto_play: 'no' | 'yes';
    enable_disa?: 'no' | 'yes';
    greetingFileName?: string;
}

export const defaultGreetingsFilesNames: {
    [key in VoicemailGreeting]: string;
} = {
    name: 'name.au',
    personal: 'personal.au',
    extended: 'extended.au',
    standard: 'standard.au',
};
